import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ITaskDetail } from '../models/task-details.model';

@Injectable({
  providedIn: 'root'
})
export class TaskDetailsService {

  private url: string = environment.API_URL + '/TaskDetail';
  private isProposalExist: string = environment.API_URL + '/TaskProposals/IsProposalExists';

  constructor(private http: HttpClient) { }


  get(id: number): Observable<ITaskDetail> {
    return this.http.get<ITaskDetail>(`${this.url}/${id}`);
  }


  IsProposalExist(userId: number, taskId: number) {
    return this.http.get<boolean>(`${this.isProposalExist}?TaskId=${taskId}&UserId=${userId}`);
  }
}
