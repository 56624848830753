import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DeleteModel } from 'src/app/shared/models/delete.model';
import { environment } from 'src/environments/environment';
import { ITaskPriority } from '../models/task-priority.model';

@Injectable({
  providedIn: 'root'
})
export class TaskPriorityService {

  private url: string = environment.API_URL + '/TaskPriorities';


  constructor(private http: HttpClient) { }

  add(model: ITaskPriority) {
    return this.http.post(this.url, model);
  }

  update(model: ITaskPriority) {
    return this.http.put(`${this.url}/${model.priorityId}`, model);
  }

  delete(id: number) {
    return this.http.delete(`${this.url}/${id}`);
  }
  deleteMultiple(deleteModel: DeleteModel) {
    return this.http.post(`${this.url}/many-delete-user-task-priorities`, deleteModel);
  }

  getAll(): Observable<ITaskPriority[]> {
    return this.http.get<ITaskPriority[]>(this.url);
  }

  getAllOnlyActive(): Observable<ITaskPriority[]> {
    return this.http.get<ITaskPriority[]>(this.url + '/GetActive');
  }


  get(id: number): Observable<ITaskPriority> {
    return this.http.get<ITaskPriority>(`${this.url}/${id}`);
  }
}
