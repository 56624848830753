import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IUserRating } from 'src/app/shared/models/user-rating';
import { environment } from 'src/environments/environment';
import { ICommonNotification } from '../models/common-notification.model';

@Injectable({
  providedIn: 'root'
})
export class CommonNotificationService {
  private userRatingUrl: string = environment.API_URL + '/UserTaskRating';

  private url: string = environment.API_URL + '/NotificationType';
  constructor(private http: HttpClient) { }

  sendNotification(req: ICommonNotification) {
    return this.http.post(this.url + "/SendCommonNotification", req);
  }
  userRating(userRating: IUserRating)
  {
    return this.http.post<IUserRating>(`${this.userRatingUrl}`, userRating)
  }
}

export class CommonNotification {
  createdBy: number;
  notificationTypeId: number;
  items: any;

  constructor(createdBy: number, notificationTypeId: number , items: any) {
    this.createdBy = createdBy;
    this.notificationTypeId = notificationTypeId;
    this.items = items;
  }
}