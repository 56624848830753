import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IPaymentType } from '../models/payment-type.model';

@Injectable({
  providedIn: 'root'
})
export class PaymentTypeService {

  private url: string = environment.API_URL + '/PaymentTypes';


  constructor(private http: HttpClient) { }

  add(model: IPaymentType) {
    return this.http.post(this.url, model);
  }

  update(model: IPaymentType) {
    return this.http.put(`${this.url}/${model.id}`, model);
  }

  delete(id: number) {
    return this.http.delete(`${this.url}/${id}`);
  }
  deleteMultiple(ids: number[]) {
    let obj = {
      ids: ids,
      isActive: false
    }
    return this.http.post(`${this.url}/many-delete-payment-types`, obj);
  }
  updateStatus(ids: number[]) {
    let obj = {
      ids: ids,
      isActive: false
    }
    return this.http.post(`${this.url}/UpdateStatus`, obj);
  }

  getAll(): Observable<IPaymentType[]> {
    return this.http.get<IPaymentType[]>(this.url);
  }
  //getAllOnlyActive

  getAllOnlyActive(): Observable<IPaymentType[]> {
    return this.http.get<IPaymentType[]>(this.url + '/GetActive');
  }

  get(id: number): Observable<IPaymentType> {
    return this.http.get<IPaymentType>(`${this.url}/${id}`);
  }
}
