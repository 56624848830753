import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ITaskProposal } from '../models/task-proposal.model';
import { IUpdateProposalAward } from '../models/update-proposal-award';
import { IUpdateProposalAwardWithdrawn } from '../models/update-proposal-award-withdrawn';
import { DeleteModel } from "../../../shared/models/delete.model";

@Injectable({
  providedIn: 'root'
})
export class TaskProposalService {

  private url: string = environment.API_URL + '/TaskProposals';
  private getTaskProposalForUserUrl: string = environment.API_URL + '/TaskProposals/GetProposalByTaskId';
  private isProposalExist: string = environment.API_URL + '/TaskProposals/IsProposalExists';
  private revertProposal: string = environment.API_URL + '/TaskProposals/ReverseProposal';
  private updateProposalAwardUrl: string = environment.API_URL + '/TaskProposals/UpdateProposalAward';
  private updateProposalAwardWithdrawnUrl: string = environment.API_URL + '/TaskProposals/UpdateProposalAwardWithdrawn';

  constructor(private http: HttpClient) { }

  add(model: ITaskProposal) {
    return this.http.post(this.url, model);
  }

  update(model: ITaskProposal) {
    return this.http.put(`${this.url}/${model.proposalId}`, model);
  }

  delete(id: number) {
    return this.http.delete(`${this.url}/${id}`);
  }
  deleteMultiple(deleteModel: DeleteModel) {
    return this.http.post(`${this.url}/many-delete-proposal`, deleteModel);
  }

  getAll(): Observable<ITaskProposal[]> {
    return this.http.get<ITaskProposal[]>(this.url);
  }

  get(id: number): Observable<ITaskProposal> {
    return this.http.get<ITaskProposal>(`${this.url}/${id}`);
  }

  getTaskProposalForUser(taskId: number, userId: number): Observable<ITaskProposal[]> {
    //return this.http.get<ITaskProposal[]>(`${this.getTaskProposalForUserUrl}?taskId=${taskId}`);
    return this.http.get<ITaskProposal[]>(`${this.getTaskProposalForUserUrl}?UserId=${userId}&TaskId=${taskId}`);
  }

  updateProposalAward(updateProposalAward: IUpdateProposalAward) {
    return this.http.patch(`${this.updateProposalAwardUrl}`, updateProposalAward);
  }

  updateProposalAwardWithdrawn(updateProposalAward: IUpdateProposalAwardWithdrawn): Observable<boolean> {
    return this.http.put<boolean>(`${this.updateProposalAwardWithdrawnUrl}`, updateProposalAward);
  }

  saveProposal(taskProposalModel: ITaskProposal) {
    return this.http.post(`${environment.API_URL}/TaskProposals`, taskProposalModel);
  }

  IsProposalExist(userId: number, taskId: number): Observable<boolean> {
    return this.http.get<boolean>(`${this.isProposalExist}?taskId=${taskId}&userId=${userId}`);
  }

  revertTaskProposal(userId: number, taskId: number): Observable<any> {
    return this.http.get<any>(`${this.revertProposal}?TaskId=${taskId}&UserId=${userId}`);
  }
}
