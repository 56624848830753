import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { IPromoCodeModel } from '../model/promo-code.model';

@Injectable({
  providedIn: 'root'
})
export class PromoCodeService {

  private url: string = environment.API_URL + '/PromoCode';


  constructor(private http: HttpClient) { }

  add(model: IPromoCodeModel) {
    return this.http.post(this.url, model);
  }

  update(model: IPromoCodeModel) {
    return this.http.put(`${this.url}/${model.id}`, model);
  }

  delete(id: number) {
    return this.http.delete(`${this.url}/${id}`);
  }
  deleteMultiple(ids: number[]) {
    let obj = {
      ids: ids,
      isActive: false
    }
    return this.http.post(`${this.url}/many-delete-privacytype`, obj);
  }

  updateStatus(ids: number[], isActive: boolean) {
    return this.http.put(`${this.url}/PromoCode`, { ids: ids, isActive: isActive });
  }

  getAll(): Observable<IPromoCodeModel[]> {
    return this.http.get<IPromoCodeModel[]>(this.url);
  }

  get(id: number): Observable<IPromoCodeModel> {
    return this.http.get<IPromoCodeModel>(`${this.url}/${id}`);
  }
}
