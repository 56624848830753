import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DeleteModel } from 'src/app/shared/models/delete.model';
import { environment } from 'src/environments/environment';
import { ITaskStatus } from '../models/task-status.model';

@Injectable({
  providedIn: 'root'
})
export class TaskStatusService {

  private url: string = environment.API_URL + '/TaskStatus';

  constructor(private http: HttpClient) { }

  addTaskStatus(taskStatus: ITaskStatus) {
    return this.http.post(this.url, taskStatus);
  }

  updateTaskStatus(taskStatus: ITaskStatus) {
    return this.http.put(this.url, taskStatus);
  }

  deleteTaskStatus(id: number) {
    return this.http.delete(`${this.url}/${id}`);
  }
  deleteMultipleTaskStatus(deleteModel: DeleteModel) {
    return this.http.post(`${this.url}/multiple-delete-taskstatus`, deleteModel);
  }

  getAllTaskStatus(): Observable<ITaskStatus[]> {
    return this.http.get<ITaskStatus[]>(this.url);
  }




  getTaskStatus(id: number): Observable<ITaskStatus> {
    return this.http.get<ITaskStatus>(`${this.url}/${id}`);
  }
}
