export interface ICommonNotification {
    createdBy: number;
    notificationTypeId: number;
    items: any;
}

export enum CommonNotificationTypes {

    MissingTaskInfo = 1,
    BillGenerated = 2, //pending
    UpdateAadharcard = 3, //pending
    Updateyourlocationforbetterproposal = 4,

    Dayspending = 6, //pending
    Updateyouremailaddress = 7, //pending
    Interestedintask = 8, //delete
    Updateproposaltype = 9, //delete
    Updatepaymenttype = 10, //delete
    Updateprice = 11, //delete
    TaskstatusChangewithComment = 12, //delete
    TaskComplete = 13, //delete

    SendRatingToUser = 15,
    NotReachableMobileNo = 16, //pending
    UpdateStatusAboutWork = 17, //delete
    PaymentDone = 18,
    RatingGivenbydropper = 19,
    Thankyoumessage = 20,
    UserinactiveLock = 21,
    Billingchargesdiscussion = 22,
    Pickernotperquality = 23,
    UrgentCall = 24,



    AskForPayment = 14,
    CreateTask = 35,
    UpdateTask = 36,
    SubmitProposal = 37,
    WithdrawProposal = 38,
    AddComment = 39,
    AssignTask = 40,
    WithdrawnTask = 41,
    TaskSatusChange = 42,
    FinishWork = 43,
    FinishedWorkPaymentReminder = 44
}