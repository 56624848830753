import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { IUsers } from '../model/users';
import { InvoiceModel } from '../model/invoice.model';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  private url: string = environment.API_URL + '/Invoice';
  private getInvoiceByUserIdUrl: string = environment.API_URL + '/Invoice/GetInvoiceUser';
  private getInvoiceByInvoiceIdUrl: string = environment.API_URL + '/Invoice';
  private getUserUrl: string = environment.API_URL + '/Users';
  private getPickerUrl: string = environment.API_URL + '/Invoice/GetInvoiceByPickerOrDropperV2';

  // GetInvoiceByPickerOrDropperV2/{isPicker}/{userId}

  constructor(private http: HttpClient) { }

  getInvoices(): Observable<InvoiceModel[]> {
    return this.http.get<InvoiceModel[]>(this.url);
  }

  getInvoiceByUserId(id: number): Observable<InvoiceModel[]> {
    return this.http.get<InvoiceModel[]>(`${this.getInvoiceByUserIdUrl}/${id}`);
  }

  getInvoiceByInvoiceId(id: number): Observable<InvoiceModel> {
    return this.http.get<InvoiceModel>(`${this.getInvoiceByInvoiceIdUrl}/${id}`);
  }

  getUsers(): Observable<IUsers[]> {
    return this.http.get<IUsers[]>(this.getUserUrl);
  }

  getPicker(picker: boolean, id: number): Observable<InvoiceModel[]> {
    return this.http.get<InvoiceModel[]>(`${this.getPickerUrl}/${picker}/${id}`);
  }

  getUsersById(id: number): Observable<IUsers> {
    return this.http.get<IUsers>(`${this.getUserUrl}/${id}`);
  }
}
