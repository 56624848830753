export enum NotificationType {
    MissingTaskInfo = 1,
    Billgenerated = 2,
    UpdateAadharcard = 3,
    Updateyourlocationforbetterproposal = 4,
    Newtaskcreated = 5,
    Dayspending = 6,
    Updateyouremailaddress = 7,
    Interestedintask = 8,
    Updateproposaltype = 9,
    Updatepaymenttype = 10,
    Updateprice = 11,
    Taskstatuschangewithcomment = 12,
    Taskcomplete = 13,
    Askforpayment = 14,
    Sendratingtouser = 15,
    Notreachablemobileno = 16,
    Updatestatusaboutwork = 17,
    Paymentdone = 18,
    Ratinggivenbydropper = 19,
    Thankyoumessage = 20,
    Userinactivelock = 21,
    Billingchargesdiscussion = 22,
    Pickernotperquality = 23,
    Urgentcall = 24
  }
  
  
  
  