import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IRoleModel } from '../model/roles.model';

@Injectable({
  providedIn: 'root'
})
export class RolesService {

  private url: string = environment.API_URL + '/Roles';


  constructor(private http: HttpClient) { }

  add(model: IRoleModel) {
    return this.http.post(this.url, model);
  }

  update(model: IRoleModel) {
    return this.http.put(`${this.url}/${model.roleId}`, model);
  }

  delete(id: number) {
    return this.http.delete(`${this.url}/${id}`);
  }
  deleteMultiple(ids: number[]){
    return this.http.post(`${this.url}/many-delete-roles`,ids);
  }

  getAll(userId: any): Observable<IRoleModel[]> {
    return this.http.get<IRoleModel[]>(`${this.url}?UserId=${userId}`);
  }

  get(id: number): Observable<IRoleModel> {
    return this.http.get<IRoleModel>(`${this.url}/${id}`);
  }
}
